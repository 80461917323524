/* main */
.top-bnr {
  position: relative;
  height: 40px;
  overflow: hidden;
  font-size: 0;
}

.top-bnr > a {
  display: block;
  font-size: 0;
}

.top-bnr > a img {
  width: auto;
  max-width: 100%;
}

.top-bnr .btn.close {
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 2;
  transform: translateY(-50%);
}

.main-visual {
  overflow: hidden;
  position: relative;
  margin-top: -56px;
}

.main-visual + .tab-bar {
  top: -1px;
}

.main-visual + .tab-bar:after {
  content: none;
}

.main-visual + .tab-bar > div {
  height: 56px;
  margin-top: -56px;
  border-radius: 15px 15px 0 0;
}

.main-visual + .tab-bar > div ul {
  padding-top: 19px;
  padding-bottom: 19px;
}

.main-visual + .tab-bar.clear:after {
  content: '';
}

.main-visual + .tab-bar.clear > div {
  height: 48px;
  margin-top: -48px;
  border-radius: 0;
}

.main-visual + .tab-bar.clear > div ul {
  padding-top: 15px;
}

.main-visual .swiper-pagination {
  position: absolute;
  top: auto;
  bottom: 91px;
  left: 24px;
  z-index: 200;
  width: calc(50% - 24px);
  height: 1px;
  background-color: #fff;
}

.main-visual .swiper-pagination-progressbar {
  position: relative;
  height: 2px;
  background: none;
}

.main-visual .swiper-pagination-progressbar:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  opacity: 0.2;
}

.main-visual .swiper-pagination-progressbar-fill {
  height: 2px;
  background-color: #111;
}

.main-visual .swiper-slide img {
  display: block;
  width: 100%;
}

.main-visual .visual-more {
  position: absolute;
  z-index: 10;
  right: 24px;
  bottom: 76px;
}

.main-visual .swiper-wrapper {
  max-height: 532px;
}

.main-quick-section {
  overflow: hidden;
  padding: 16px 24px;
}

.main-quick-section ul {
  display: flex;
  flex-wrap: wrap;
  margin: -24px 0 0 -16px;
}

.main-quick-section ul > li {
  width: 20%;
  margin-top: 24px;
  padding-left: 16px;
}

.main-quick-section ul > li > a {
  overflow: hidden;
  text-align: center;
}

.main-quick-section ul > li > a .thumb {
  overflow: hidden;
  width: 52px;
  height: 52px;
  background-color: #f5f5f5;
  border-radius: 100%;
}

.main-quick-section ul > li > a .thumb img {
  width: 100%;
}

.main-quick-section ul > li > a .txt {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #333;
  word-break: keep-all;
}

.main-bann-box {
  padding: 32px 24px 48px;
}

.main-bann-box .bann-slide2 {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.main-bann-box .bann-slide2 .btn.ind-ty1 {
  left: auto;
  right: 8px;
  bottom: 8px;
  background: rgba(0, 0, 0, 0.4);
  border: none;
  width: 50px;
}

.main-bann-box .bann-slide2 .btn.ind-ty1 .pg {
  color: #FFF;
}

.main-bann-box .bann-slide2 .btn.ind-ty1 .pg .btn.ind-ty1 .total {
  opacity: .5;
  color: #FFF;
}

.main-top {
  display: flex;
  justify-content: space-between;
  padding: 0 24px 20px;
}

.main-top .tit {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  color: #1d1d1b;
}

.main-top .btn {
  background: transparent;
  line-height: 32px;
}

.main-cont > .l-wrap {
  margin-bottom: -20px;
}

.main-section {
  padding: 32px 0;
}

.main-section .prd-lists .prd-item .desc {
  margin-top: 8px;
}

.main-section:last-child {
  padding-bottom: 48px;
}

.main-section.newest + .main-bann-box,
.main-section.newest + script + .main-bann-box {
  padding-top: 0;
}

.main-section.timedeal {
  padding: 48px 0;
  background: url(../images/main/main_timedeal_bg.png) no-repeat left bottom/cover;
}

.main-section.timedeal .main-top {
  flex-direction: column;
}

.main-section.timedeal .main-top .tit + .time-event {
  margin-top: 4px;
}

.main-section.timedeal .prd-lists {
  overflow: hidden;
  padding: 0 24px 16px;
}

.main-section.timedeal .prd-lists .swiper-slide:first-child .prd-item {
  margin-left: 0;
}

.main-section.timedeal .prd-lists .prd-item {
  margin-left: 8px;
  position: relative;
  background-color: #FFF;
  border-radius: 8px;
}

.main-section.timedeal .prd-lists .prd-item:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
}

.main-section.timedeal .prd-lists .prd-item .thumbs {
  flex: none;
  height: 204px;
  padding: 8px 0;
  text-align: center;
}

.main-section.timedeal .prd-lists .prd-item .thumbs > a {
  display: block;
  height: 100%;
}

.main-section.timedeal .prd-lists .prd-item .thumbs > a img {
  overflow: hidden;
  border-radius: 4px;
  width: auto;
}

.main-section.timedeal .prd-lists .prd-item .desc {
  margin-top: 0;
  padding: 0 0 20px 20px;
}

.main-section.timedeal .prd-lists .prd-item .desc .name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 20px;
  font-size: 15px;
  line-height: 16px;
  color: #1d1d1b;
}

.main-section.timedeal .prd-lists .prd-item .desc .pd_info {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-top: 4px;
  padding-right: 20px;
  font-size: 13px;
  line-height: 16px;
  color: #888;
}

.main-section.timedeal .prd-lists .prd-item .desc .price {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
  padding-right: 50px;
  font-size: 24px;
  line-height: 30px;
}

.main-section.timedeal .prd-lists .prd-item .desc .price del {
  padding-left: 6px;
  font-size: 13px;
}

.main-section.timedeal .prd-lists .prd-item .add-cart-bt, .main-section.timedeal .prd-lists .prd-item .add-alarm-bt {
  top: auto;
  right: 16px;
  bottom: 20px;
}

.main-section.recommand + .only {
  border-top: 1px solid #f1f1f1;
}

.main-section.only + .md {
  border: 1px solid #f1f1f1;
}

.main-section.md .main-top {
  padding-bottom: 8px;
}

.main-section.md .main-cont .story-item .thumb {
  position: relative;
}

.main-section.md .main-cont .story-item .thumb img {
  width: 100%;
}

.main-section.md .main-cont .story-item .thumb .btn {
  position: absolute;
  right: 16px;
  bottom: 16px;
  background-color: rgba(255, 255, 255, 0.7);
}

.main-section.md .main-cont .story-item .thumb .btn.ar-b:after {
  margin-top: 0;
}

.main-section.md .main-cont .story-item .cont {
  padding: 20px 24px 0;
}

.main-section.md .main-cont .story-item .cont .tit {
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;
  color: #1d1d1b;
}

.main-section.md .main-cont .story-item .cont .desc {
  font-size: 15px;
  line-height: 21px;
  color: #333;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.main-section.mileage {
  padding: 48px 0 33px;
  background: url(../images/main/main_mileage_bg.png) no-repeat center top/cover;
}

.main-section.mileage .prd-lists {
  overflow: hidden;
  padding: 0 24px 15px;
}

.main-section.mileage .prd-lists .swiper-slide:first-child .prd-item {
  margin-left: 0;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item {
  position: relative;
  margin-left: 8px;
  background-color: #FFF;
  border-radius: 8px;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .my-mileage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 16px;
  background-color: #f9f2ec;
  line-height: 24px;
  font-weight: bold;
  color: #333;
  border-radius: 8px 8px 0 0;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .my-mileage dt {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .my-mileage dt span {
  font-size: 13px;
  padding-left: 4px;
  vertical-align: middle;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .my-mileage dd {
  font-size: 18px;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .benefit-bann {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background-color: #f9f2ec;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  border-radius: 8px 8px 0 0;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .benefit-bann span {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  line-height: 24px;
  padding-left: 5px;
  color: #333;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .thumbs {
  flex: 0 0 100%;
  height: 255px;
  padding: 60px 0 8px;
  text-align: center;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .thumbs a {
  display: block;
  height: 100%;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .thumbs a img {
  width: auto;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .desc {
  margin-top: 0;
  padding: 0 52px 20px 20px;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .desc .name {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .desc .name + .price {
  margin-top: 6px;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .desc .price {
  display: flex;
  align-items: center;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .desc .price img {
  margin-right: 6px;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .desc .price .amount {
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
}

.main-section.mileage .prd-lists .swiper-slide .prd-item .add-cart-bt, .main-section.mileage .prd-lists .swiper-slide .prd-item .add-alarm-bt {
  z-index: 5;
  right: 20px;
  top: auto;
  bottom: 20px;
}

.main-section.best + .yellow,
.main-section.best + script + .yellow {
  border-top: 1px solid #f1f1f1;
}

.main-section.best + .recipe,
.main-section.best + script + .recipe {
  border-top: 1px solid #f1f1f1;
}

.main-section.yellow + .recipe,
.main-section.yellow + script + .recipe {
  border-top: 1px solid #f1f1f1;
}

.main-section.recipe .main-top {
  padding-bottom: 8px;
}

.main-section.recipe .main-cont .cook-item .thumb img {
  width: 100%;
  min-height: 288px;
  object-fit: cover;
}

.main-section.recipe .main-cont .cook-item .cont {
  padding: 20px 24px 0;
}

.main-section.recipe .main-cont .cook-item .cont .desc {
  font-size: 15px;
  line-height: 21px;
  color: #333;
  letter-spacing: -.4px;
}

.main-section.recipe .main-cont .cook-item .cont .desc + .cook-tip-box {
  margin-top: 16px;
}

.main-section.recipe .main-cont .cook-tip-box {
  overflow: hidden;
  padding: 20px 24px 16px;
}

.main-section.recipe .main-cont .cook-tip-box .prd-chk-area {
  position: relative;
  padding: 16px 17px 0 18px;
  border: 1px solid #d3233a;
  border-radius: 8px;
}

.main-section.recipe .main-cont .cook-tip-box .prd-chk-area:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists {
  padding-bottom: 53px;
  height: fit-content;
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-slide {
  display: flex;
  width: calc(100% / 3);
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-slide .prd-chk-div {
  width: 100%;
  margin-right: 8px;
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-slide .prd-chk-div:last-child {
  margin-right: 0;
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-slide .prd-chk-div .chkbox {
  position: absolute;
  top: 0;
  left: 1px;
  z-index: 5;
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-slide .prd-chk-div .chkbox .text {
  padding-left: 20px;
  font-size: 0;
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-slide .prd-chk-div .chkbox > label > input[type="checkbox"] + .text {
  font-size: 0;
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-slide .prd-chk-div .prd-item {
  width: auto;
  flex: 1;
  padding-right: 0;
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-slide .prd-chk-div .prd-item .thumbs {
  flex: 1;
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-slide .prd-chk-div .prd-item .desc {
  margin: 2px 8px 0;
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-slide .prd-chk-div .prd-item .desc .amount {
  font-size: 14px;
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-slide .prd-chk-div .prd-item .desc .price {
  margin-top: 0;
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-slide .prd-chk-div .prd-item .desc .add-cart-bt,
.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-slide .prd-chk-div .prd-item .desc .add-alarm-bt {
  z-index: 5;
  top: -36px;
  right: -6px;
}

.main-section.recipe .main-cont .cook-tip-box .prd-lists .swiper-pagination.ind-ty1 {
  left: 50%;
  bottom: 18px;
  transform: translateX(-50%);
}

.main-section.recipe .main-cont .cook-tip-box .btn-area {
  overflow: hidden;
  margin: 0 -18px;
  border-radius: 0 0 7px 7px;
}

.main-section.recipe .main-cont .cook-tip-box .btn-area > a,
.main-section.recipe .main-cont .cook-tip-box .btn-area > button {
  border-radius: 0;
  flex-direction: unset;
}

.main-section.recipe .main-cont .cook-tip-box .btn-area > a.like,
.main-section.recipe .main-cont .cook-tip-box .btn-area > button.like {
  position: relative;
}

.main-section.recipe .main-cont .cook-tip-box .btn-area > a.like > span,
.main-section.recipe .main-cont .cook-tip-box .btn-area > button.like > span {
  width: 16px;
  height: 16px;
  background-image: url(../images/icon_heart_w.svg);
  margin-right: 6px;
}

.main-section.recipe .main-cont .cook-tip-box .btn-area > a.like > span.on,
.main-section.recipe .main-cont .cook-tip-box .btn-area > button.like > span.on {
  background-image: url(../images/icon_heart_full_w.svg);
}

.main-section.recipe + .yellow,
.main-section.recipe + script + .yellow {
  padding-top: 0;
  border-bottom: 1px solid #f1f1f1;
}

/* main */
