/* main */
@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin line-clamp-1 {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin line-clamp-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@mixin line-clamp-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}



//top 배너
.top-bnr {
  position: relative;
  height: 40px;
  overflow: hidden;
    font-size: 0;
    > a {
      display: block;
      font-size: 0;
      img {
        width: auto;
        max-width: 100%;
      }
    }
    .btn {
      &.close{
        position: absolute;
        top: 50%;
        right:20px;
        z-index: 2;
        transform: translateY(-50%);
      } 
    }
}

// 비주얼 배너 
.main-visual {
  overflow:hidden;
  position: relative;
  margin-top: -56px;

  & + .tab-bar {
    top:-1px;

    &:after {
      content:none;
    }

    > div {
      height: 56px;
      margin-top:-56px;
      border-radius:15px 15px 0 0;
      ul {
        padding-top: 19px;
        padding-bottom: 19px;
      }
    }

    &.clear {
      &:after {
        content:'';
      }
      > div {
        height: 48px;
        margin-top: -48px;
        border-radius:0;
        ul {
          padding-top: 15px;
        }
      }
    }
  }

  .swiper-pagination {
    position: absolute;
    top:auto;
    bottom:91px;
    left: 24px;
    z-index: 200;
    width:calc(50% - 24px);
    height: 1px;
    background-color: #fff;

    &-progressbar {
      position: relative;
      height: 2px;
      background: none;
      &:before {
        content:'';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #000;
        opacity:0.2;
      }
      
      &-fill {
        height: 2px;
       background-color: #111;
      }
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }

  .visual-more {
    position: absolute;
    z-index: 10;
    right:24px;
    bottom:76px;
  }

  .swiper-wrapper{
    max-height: 532px;
  }
}

.main {
  &-quick-section {
    overflow:hidden;
    padding: 16px 24px;
    ul {
      display: flex;
      flex-wrap:wrap;
      margin: -24px 0 0 -16px;
      
      > li {
        width: 20%;
        margin-top: 24px;
        padding-left: 16px;

        > a {
          overflow:hidden;
          text-align: center;

          .thumb {
            overflow: hidden;
            width: 52px;
            height: 52px;
            background-color: #f5f5f5;
            border-radius:100%;
            img {
              width: 100%;
            }
          }
          .txt {
            margin-top: 8px;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            color:#333;
            word-break: keep-all;
          }
        }
      }
    }
  }

  &-bann-box {
    padding: 32px 24px 48px;
    
    .bann-slide2 {
      position: relative;
      overflow:hidden;
      border-radius:8px;
      .btn {
        &.ind-ty1 {
          left:auto;
          right:8px;
          bottom:8px;
          background: rgba(0, 0, 0, 0.4);
          border: none;
          width: 50px;
          .pg {
            color:#FFF;
            .btn {
              &.ind-ty1 {
                .total {
                  opacity:.5;
                  color:#FFF;
                }
              }
            }
          }
        }
      }
    }
  }

  &-top {
    display: flex;
    justify-content: space-between;
    padding: 0 24px 20px;

    .tit {
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      color:#1d1d1b;
    }
    .btn {
      background: transparent;
      line-height: 32px;
    }
  }

  &-cont {
    > .l-wrap {
      margin-bottom: -20px;
    }
  }
}


// section별
.main-section {
  padding: 32px 0;
  .prd-lists .prd-item .desc{margin-top: 8px;}

  &:last-child {
    padding-bottom: 48px;
    //padding-bottom: 80px;
  }

  &.newest {
    & + .main-bann-box,
    & + script + .main-bann-box {
      padding-top: 0;

    }
  }

  &.timedeal {
    padding: 48px 0;
    background: url(../images/main/main_timedeal_bg.png) no-repeat left bottom/cover;

    .main-top {
      flex-direction: column;
      .tit {
        & + .time-event {
          margin-top: 4px;
        }
      }

    }
    
    .prd-lists {
      overflow:hidden;
      padding:0 24px 16px;

      .swiper-slide:first-child {
        .prd-item {
          margin-left: 0;
        }
      }

      .prd-item {
        margin-left: 8px;
        position: relative;
        background-color: #FFF;
        border-radius: 8px;

        &:after {
          content:'';
          position: absolute;
          top:0;
          left:0;
          z-index: -1;
          width: 100%;
          height: 100%;
          box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
        }

        .thumbs {
          flex:none;
          height: 204px;
          padding: 8px 0;
          text-align: center;
          > a {
            display: block;
            height: 100%;
            img {
              overflow:hidden;
              border-radius:4px;
              width: auto;
            }
          }
        }
        .desc {
          margin-top: 0;
          padding : 0 0 20px 20px;
          .name {
            @include line-clamp-1;
            padding-right: 20px;
            font-size: 15px;
            line-height: 16px;
            color:#1d1d1b;
          }
          .pd_info {
            @include line-clamp-3;
            margin-top: 4px;
            padding-right: 20px;
            font-size: 13px;
            line-height: 16px;
            color:#888;
          }
          .price {
            display: flex;
            align-items: center;
            flex-wrap:wrap;
            margin-top: 8px;
            padding-right: 50px;
            font-size: 24px;
            line-height: 30px;
            del {
              padding-left: 6px;
              font-size: 13px;
            }
          }
        }
        .add-cart-bt,.add-alarm-bt {
          top:auto;
          right:16px;
          bottom:20px;
        }
      }
    }
  }

  &.recommand {
    & + .only {
      border-top:1px solid #f1f1f1;
    }
  }

  &.only {
    & + .md {
      border: 1px solid #f1f1f1;
    }
  }

  &.md {
    .main-top {
      padding-bottom: 8px;
    }
    .main-cont {
      .story-item {
        .thumb {
          position: relative;
          img {
            width: 100%;
          }
          .btn {
            position: absolute;
            right:16px;
            bottom:16px;
            background-color: rgba(255, 255, 255, 0.7);

            &.ar-b {
              &:after {
                margin-top: 0;
              }
            }
          }
        }
        .cont {
          padding: 20px 24px 0;
          .tit {
            margin-bottom: 8px;
            font-size: 22px;
            font-weight: 800;
            line-height: 28px;
            color:#1d1d1b;
          }
          .desc {
            font-size: 15px;
            line-height: 21px;
            color:#333;
            @include line-clamp-3;
          }
        }
      }
    }
  }

  &.mileage {
    padding: 48px 0 33px;
    background: url(../images/main/main_mileage_bg.png) no-repeat center top / cover;

    .prd-lists {
      overflow:hidden;
      padding:0 24px 15px;

      .swiper-slide{
        &:first-child {
          .prd-item {
            margin-left: 0;
          }
        }
        .prd-item {
          position: relative;
          //width: 327px;
          margin-left: 8px;
          background-color: #FFF;
          border-radius:8px;

          &:after {
            content:'';
            position: absolute;
            left:0;
            top:0;
            z-index: -1;
            width: 100%;
            height: 100%;
            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
          }

          .my-mileage {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            padding: 10px 16px;
            background-color: #f9f2ec;
            line-height: 24px;
            font-weight: bold;
            color:#333;
            border-radius: 8px 8px 0 0;

            dt {
              display: flex;
              align-items: center;
              font-size: 18px;
              span {
                font-size: 13px;
                padding-left: 4px;
                vertical-align: middle;
              }
            }
            dd {
              font-size: 18px;
            }
          }
          .benefit-bann {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            padding: 10px 0;
            background-color: #f9f2ec;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            border-radius: 8px 8px 0 0;

            span {
              display: inline-block;
              font-size: 13px;
              font-weight: bold;
              line-height: 24px;
              padding-left: 5px;
              color:#333;
            }
          }

          .thumbs {
            flex: 0 0 100%;
            height: 255px;
            padding: 60px 0 8px;
            text-align: center;
            a {
              display: block;
              height: 100%;
              img {
                width: auto;
              }
            }
          }
          .desc {
            margin-top: 0;
            padding: 0 52px 20px 20px;

            .name {
              font-size: 15px;
              font-weight: 500;
              line-height: 18px;

              & + .price {
                margin-top:6px;
              }
            }
            .price {
              display: flex;
              align-items: center;
              img {
                margin-right: 6px;
              }
              .amount {
                font-size: 24px;
                font-weight: bold;
                line-height: 30px;
              }
            }
          }

          .add-cart-bt,.add-alarm-bt {
            z-index: 5;
            right: 20px;
            top:auto;
            bottom: 20px;
          }
        }
      }
    }
  }

  &.best {
    & + .yellow,
    & + script + .yellow {
      border-top:1px solid #f1f1f1;
    }

    & + .recipe,
    & + script + .recipe {
      border-top:1px solid #f1f1f1;
    }
  }

  &.yellow {
    & + .recipe,
    & + script + .recipe {
      border-top:1px solid #f1f1f1;
    }
    
  }

  &.recipe {
    .main-top {
      padding-bottom: 8px;
    }
    .main-cont {
      .cook-item {
        .thumb {
          img {
            width: 100%;
            min-height:288px;
            object-fit: cover;
          }
        }
        .cont {
          padding: 20px 24px 0;
          .desc {
            font-size: 15px;
            line-height: 21px;
            color:#333;
            letter-spacing: -.4px;
            & + .cook-tip-box {
              margin-top: 16px;
            }
          }
        }
      }

      .cook-tip-box {
        overflow:hidden;
        padding: 20px 24px 16px;

        .prd-chk-area {
          // overflow:hidden;
          position: relative;
          padding:16px 17px 0 18px;
          border:1px solid #d3233a;
          border-radius:8px;
          //box-shadow:0 0 0 1px #d3233a inset;

          &:after {
            content:'';
            position: absolute;
            left: 0;
            bottom:0;
            z-index: -1;
            width: 100%;
            height: 100%;
            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
            border-radius:8px;
          }
        }
      
        .prd-lists {
          padding-bottom: 53px;
          height:fit-content;

          .swiper-slide {
            display: flex;
            width: calc(100% / 3);
            .prd-chk-div {
              width: 100%;
              margin-right: 8px;
              &:last-child {
                margin-right: 0;
              }
              .chkbox {
                position: absolute;
                top:0;
                left:1px;
                z-index: 5;
                .text {
                  padding-left: 20px;
                  font-size: 0;
                }
                > label {
                  > input[type="checkbox"] + .text {
                    font-size: 0;
                  }
                }
              }
              .prd-item {
                width:auto;
                flex: 1;
                padding-right: 0;
                .thumbs {
                  flex:1;
                  // width: 92px;
                  // height: 92px;
                }
                .desc {
                  margin: 2px 8px 0;
                  .amount {
                    font-size: 14px;
                  }
                  .price{
                    margin-top: 0;
                  }
                  .add-cart-bt,
                  .add-alarm-bt{
                    z-index: 5;
                    top:-36px;
                    right:-6px;
                  }
                }
              }
            }
          }
          .swiper-pagination {
            &.ind-ty1 {
              left:50%;
              bottom:18px;
              transform: translateX(-50%);
            }
          }
        }
      
        .btn-area {
          overflow:hidden;
          margin: 0 -18px;
          border-radius: 0 0 7px 7px;
          > a,
          > button {
            border-radius:0;
            flex-direction: unset;
            &.like {
              position: relative;
              // flex: 0 0 48px;
              > span {
                width: 16px;
                height: 16px;
                background-image: url(../images/icon_heart_w.svg);
                margin-right: 6px;

                &.on{
                  background-image: url(../images/icon_heart_full_w.svg);
                }
              }
      
              // &:before {
              //   content:'';
              //   position: absolute;
              //   top:0;
              //   right:-1px;
              //   width: 1px;
              //   height:100%;
              //   background-color: #FFF;
              //   opacity:.3;
              // }
            }
          }
        }
      }
    }

    & + .yellow,
    & + script + .yellow {
      padding-top:0;
      border-bottom:1px solid #f1f1f1;
    }
  }
}


/* main */